var cart_timer_interval = null;
var startingTime = 0;
class MinicartGloIT extends BATComponent {
	beforeLoad() {
		//
	}

	afterLoad() {
		//

		this.$el = $(this);

		window.addEventListener('strength-table-available', this.bindEvents());

		//const cartId = Utils.getCookie('commerce-cart-id');

		/*if (cartId) {
			Commerce.getCart(cartId);
		}*/

		// Cart event
		$(window).on('cart-update', () => {
			this.applyCartToDM();
		});

		$(window).on('mixed-cart', () => {
			this.$el.find('.bat-minicart-gloit-warning-message').removeClass('uk-hidden');
		});

		this.appendCarousel();
		this.closeWarningMessage();
	}

	async bindEvents() {
	
		const loginToken = Utils.getCookie('commerce-auth-verify') === 'true';
		const isCheckout = window.location.href.indexOf('checkout');

		this.$el.find('.bat-minicart-gloit-close').click(() => {
			this.$el.find('.bat-minicart-gloit').addClass('d-none');
		});

		this.$el.find('.bat-minicart-gloit-button-checkout a').click((e) => {
			e.preventDefault();
			const checkoutUrl = e.currentTarget.getAttribute('href');

			if (loginToken) {
				window.location = checkoutUrl;
			} else {
				window.location = `/login?resource=${checkoutUrl}`;
			}
		});

		this.$el.find('.bat-minicart-gloit-button-edit-checkout a').click((e) => {
			e.preventDefault();
			const checkoutUrl = e.currentTarget.getAttribute('href');

			if (loginToken) {
				window.location = checkoutUrl;
			} else {
				UIkit.modal(document.getElementById('modal-quick-login')).show();
			}
		});

		// Stop clicks inside from bubbling up to document
		this.$el.on('click', (e) => {
			e.stopPropagation();
		});

		this.$el.find('.bat-minicart-gloit-button-edit button').click(() => {
			window.location = this.data.viewEditCartUrl;
		});

		this.$el.find('.bat-minicart-gloit-warning-message .yes-button').click(() => {
			const productObject = JSON.parse(Utils.getLocalStorage('commerce-product-pending'));
			if(productObject) {
				this.$el.find('.bat-minicart-gloit-warning-message').addClass('active');
				Commerce.clearCartAndAddItem(productObject).then(async () => {
					this.$el.find('.bat-minicart-gloit-warning-message').removeClass('active');
					this.$el.find('.bat-minicart-gloit-warning-message').addClass('uk-hidden');		
					let cartId = Utils.getCookie('commerce-cart-id');
					if(cartId){
						const getCart = await Commerce.getCart(cartId);
						if(getCart && getCart.data && getCart.data.cart){
							if(getCart.data.cart.cart_expiry.expires_at != null){
								let expires_at = getCart.data.cart.cart_expiry.expires_at;
								let expires_at_date = new Date(expires_at.replace(/\s+/g, 'T') + 'Z');
								let today = new Date();
								let differenzaInMillisecondi = Math.abs(expires_at_date-today);
								let differenceInSeconds = Math.floor(differenzaInMillisecondi/ 1000); // 1 secondo = 1000 
								startingTime= differenceInSeconds;
								localStorage.setItem('cart-timer',startingTime);
								if(cart_timer_interval != null){
									clearInterval(cart_timer_interval)
								}
								cart_timer_interval = setInterval(function(){
									let minutes = Math.floor(startingTime / 60);
									let seconds = startingTime % 60;

									let timeFormatted = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
									$(".bat-header-cart-timer").text(`Completa l'ordine in ${timeFormatted}`);
									$(".bat-header-timer").css("display","block");
									window.innerWidth < 500 && $(".bat-header-loyalty-info").css("display","none");
									startingTime= startingTime-1;
									localStorage.setItem('cart-timer',startingTime);

									if (startingTime < 0) {
										clearInterval(cart_timer_interval);
										$(".bat-header-cart-timer").text("");
										window.innerWidth < 500 && $(".bat-header-loyalty-info").css("display","flex") && $(".bat-header-timer").css("display","none");
									}
									
								}, 1000);
							}
						}
					}
				});
			}
		});

		this.$el.find('.bat-minicart-gloit-warning-message .no-button').click(() => {
			Utils.removeLocalStorage('commerce-product-pending');
			this.$el.find('.bat-minicart-gloit-warning-message').addClass('uk-hidden');
		});

		this.$el.find('.remove-item').click(async (event) => {
			const trash = $(event.currentTarget);
			const id = trash.parent().data('itemId');
			if(id) {
				trash.closest('.bat-minicart-gloit-cart-item-container').addClass('active');
				await Commerce.removeItemFromCart(id);
				const productObject = $.parseJSON(
					Utils.getLocalStorage('commerce-cart-storage')
				);
				const deletedProductID = trash.parent().data('itemId').toString();
				const foundItem = productObject.data.cart.items.find(item=>item.id === deletedProductID);
				if(foundItem){
					if (typeof dataLayer !== 'undefined') {
						dataLayer.push({
							event: 'remove_from_cart',
							ecommerce: {
								items: [
									{
										item_name: foundItem.product.name,
										item_id: foundItem.product.id.toString(),
										price: foundItem.prices.row_total_including_tax.value.toFixed(2),
										item_brand: 'Glo',
										item_category: productObject.categories.categories[0].name,
										item_category_2: '',
										item_category_3: '',
										item_category_4: '',
										item_variant: '',
										item_list_name: '',
										item_list_id: '',
										index: '1',
										quantity: foundItem.quantity.toString(),
									},
								],
							},
						});
					}
				}
				
				if (isCheckout > -1) {
					window.location.reload();
				}
			}
		});

		const model = this.data;
		const cartData = model.cart;

		if (cartData) {
			let cartTotalQuantity = 0;

			const { items } = cartData;
			items.forEach((item) => {
				cartTotalQuantity += item.quantity;
			});
			/*
			const cartSubtotal = document.querySelector(
				'.bat-minicart-gloit-subtotal-value'
			);

			if (cartSubtotal) {
				const quantityLabel = cartSubtotal.querySelector(
					'.bat-minicart-gloit-subtotal-value-quantity'
				);
				const quantityText = `(${cartTotalQuantity} ${model.cartTotalProductsLabel})`;
				if (quantityLabel) {
					quantityLabel.innerText = quantityText;
				} else {
					cartSubtotal.innerHTML += `
						<span class="bat-minicart-gloit-subtotal-value-quantity">
							${quantityText}
						</span>
					`;
				}
			}*/
			const cartSubtotal = document.querySelector(
				'.bat-minicart-gloit-subtotal-value'
			);
			if (cartSubtotal) {
				const quantityLabelItems = document.querySelector(
					'.bat-minicart-gloit-subtotal-items'
				);
				if (quantityLabelItems) {
					if (cartTotalQuantity > 1) {
						const quantityText = `(${cartTotalQuantity} proizvoda)`;
						quantityLabelItems.innerText = quantityText;
					} else {
						const quantityText = `(${cartTotalQuantity} proizvod)`;
						quantityLabelItems.innerText = quantityText;
					}
					
				}
			}
		}

		// Set Incrementers
		const cartItems = this.$el.find('.bat-minicart-gloit-cart-item');

		$.each(cartItems, (index, item) => {
			const $quantity = $(item).find('.bat-quantity');
			Forms.bindIncrementer($quantity);
		});

		this.$el.find('.bta-icon.icon-minus, .bta-icon.icon-plus').click(async (event) => {
			event.preventDefault();

			const $button = $(event.currentTarget);
			const $itemContainer = $button.closest('.bat-minicart-gloit-cart-item-container');
			const itemId = $itemContainer.find('.bat-minicart-gloit-cart-item-product-container-infos').attr('data-item-id');
			const $quantityElement = $itemContainer.find('.counter-number');
			let currentQuantity = parseInt($quantityElement.text().trim()); 

			const isIncrement = $button.hasClass('icon-plus');
			

			if (!isIncrement && currentQuantity <= 1) return;

			$itemContainer.addClass('active');
			currentQuantity = isIncrement ? currentQuantity + 1 : currentQuantity - 1;
			console.log(currentQuantity);

			try {
				const resp = await Commerce.updateItemInCart(itemId, currentQuantity);
				console.log(resp);
		
				if (resp && resp.data && resp.data.updateCartItems && resp.data.updateCartItems.cart) {
				
					const updatedQuantity = resp.data.updateCartItems.cart.items.find(item => item.id === itemId).quantity;
					$quantity.text(updatedQuantity);
	
					$itemContainer.removeClass('active');
				}
			} catch (error) {
				console.error('Errore aggiornamento carrello', error);
	
			}


		});
	}

	applyCartToDM() {
		const cart = $.parseJSON(
			Utils.getLocalStorage('commerce-cart-storage')
		);

		let isWalletProducts = false;
		/* eslint-disable-next-line no-console */
		console.log('cart', cart);

		if(cart && cart.data.cart.items.length == 0){
			clearInterval(cart_timer_interval);
			$(".bat-header-cart-timer").text("");
			window.innerWidth < 500 && $(".bat-header-loyalty-info").css("display","flex") && $(".bat-header-timer").css("display","none");		
		}

		let dm = {};

		if (this.$el.attr('data-model')) {
			dm = $.parseJSON(this.$el.attr('data-model'));
		}

		if (cart) {
			dm.cart = cart.data.cart;
		}

		const urlPath = dm.productHeroUrl;
		if(dm.cart.items && dm.cart.items.length > 0) {
			for(const item of dm.cart.items) {
				let product = item.product;
				if(item.configurable_options && item.configurable_options.length > 0 && product.__typename === 'ConfigurableProduct') {
					const valueUid = item.configurable_options[0].configurable_product_option_value_uid;
					if(valueUid) {
						product = product.variants.filter((variant) =>
							variant.attributes.some(
								(attrib) => attrib.uid === valueUid
							)
						)[0]
						item.product = product.product;
					}
				}
				if(product.is_wallet_product == '1') {
					isWalletProducts = true;
				}
				if(product.parent_url_key) {
					const urlKey = product.parent_url_key;
					const urlSuffix = `/${urlKey}`;
					let newPath = urlPath;
					if(isWalletProducts) {
						newPath = newPath.replace("shop", "gloers-shop");
					}
					product.productHeroUrl = newPath + urlSuffix;
				} else {
					const urlKey = product.url_key;
					const urlSuffix = `/${urlKey}`;
					let newPath = urlPath;
					if(isWalletProducts) {
						newPath = newPath.replace("shop", "gloers-shop");
					}
					product.productHeroUrl = newPath + urlSuffix;
				}
			}
		}

		dm.cart.isWalletProducts = isWalletProducts;

		if(!this.$el.find('.bat-minicart-gloit').hasClass('d-none')) {
			dm.wasOpened = true;
		}

		$(this).attr('data-model', JSON.stringify(dm));
	}



	formatPrices() {
		this.$el.find('.formatPrice').each((k, v) => {
			if(!$(v).hasClass('walletPrice')) {
				$(v).html(
					'CENA: '+Commerce.priceFormatter(
						$(v).data('price'),
						$(v).data('currency')
					)
				);
			} else {
				$(v).html($(v).data('price'));
			}
		});
	}

	beforeUpdate() {
		//
	}

	afterUpdate() {
		//
		if(this.data.wasOpened) {
			this.$el.find('.bat-minicart-gloit').removeClass('d-none');
		}

		this.bindEvents();
		this.formatPrices();
		this.appendCarousel();
		this.closeWarningMessage();
		this.setEditViewButton();
	}

	unload() {
		//
	}

	setEditViewButton() {
		$('.bat-cta-style.view-edit-button').on("click",(e) => {
			$(this).removeAttr("href");
			$(".bat-minicart-gloit").addClass("d-none");
			e.stopPropagation();
			e.preventDefault();
		});
	}

	appendCarousel() {
		let carousel = document.querySelector('.minicart-carousel');
		let minicart = document.querySelector('.bat-minicart-gloit');

		if (carousel) {
			minicart.append(carousel)
		}
	}

	closeWarningMessage() {
		let hr = document.querySelectorAll('hr');
		let warningMessage = document.querySelector('.bat-minicart-gloit-warning-message');
		/*let buttons = warningMessage.children[3].children

		buttons[0].addEventListener('click',() => {
			warningMessage.classList.add('hidden');
			hr[1].classList.add('hidden');
		});

		buttons[1].addEventListener('click',() => {
			warningMessage.classList.add('hidden');
			hr[1].classList.add('hidden');
		});*/
	}
}

!customElements.get('bat-minicart-gloit') &&
	customElements.define('bat-minicart-gloit', MinicartGloIT);
